import React, {
    useContext
} from "react";
import {
    Button
} from "react-bootstrap";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";
import Helmet from "../components/Helmet";
import Logo from "../components/Logo";

function Account() {
    const authenticatedUser = useContext(AuthenticatedUserContext);
    return (
        <React.Fragment>
            <Helmet title="Account"/>
            <div className="background-login">
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <h1 className="text-white text-center mt-3">Account</h1>
                    <div className="card mt-2" style={{width: "100%", maxWidth: "500px"}}>
                        <div className="card-body">
                            <h5 className="card-title">Gegevens</h5>
                            <p className="card-text">
                                <strong>Naam:</strong> {authenticatedUser.user.name}<br/>
                                <strong>E-mail:</strong> {authenticatedUser.user.email}
                            </p>
                            <Button
                                variant="secondary"
                                onClick={ authenticatedUser.logoutFunction }
                                disabled={ authenticatedUser.loading }
                            >
                                Uitloggen
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Account);
