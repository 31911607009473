import React from "react";

import TabBarLink from "./TabBarLink";

function TabBar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-bottom tabbar">
            <div className="container">
                <ul className="navbar-nav flex-row justify-content-around w-100">
                    <TabBarLink to="/discover" title="Ontdek" icon="fa-solid fa-compass"/>
                    <TabBarLink to="/account" title="Account" icon="fa-solid fa-user"/>
                </ul>
            </div>
        </nav>
    );
}

export default React.memo(TabBar);
