import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Alert
} from "react-bootstrap";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

function Register() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [error, setError] = useState(null);

    const authenticatedUser = useContext(AuthenticatedUserContext);

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
        };
    }, []);
    const state = useMemo(() => {
        return {
            fullName,
            email,
            password,
            passwordConfirm,
            acceptTerms
        };
    }, [fullName, email, password, passwordConfirm, acceptTerms]);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            if(!state.acceptTerms) {
                setError("Je moet akkoord gaan met de privacyverklaring.");
                return;
            }
            setError(null);
            authenticatedUser.registerFunction(state, onError);
        };
    }, [authenticatedUser, onError, state]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card">
                <div className="card-body">
                    <form id="login-form" onSubmit={ onSubmit }>
                        <div className="form-group">
                            <label htmlFor="email">Volledige naam</label>
                            <input
                                type="text" className="form-control" id="fullName" name="fullName"
                                value={fullName} onChange={(event) => setFullName(event.target.value)}
                                required
                                autoComplete="name"
                                disabled={ authenticatedUser.loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email" className="form-control" id="email" name="email"
                                value={email} onChange={(event) => setEmail(event.target.value)}
                                required
                                autoComplete="email"
                                disabled={ authenticatedUser.loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Wachtwoord</label>
                            <input
                                type="password" className="form-control" id="password" name="password"
                                value={password} onChange={(event) => setPassword(event.target.value)}
                                required
                                autoComplete="new-password"
                                disabled={ authenticatedUser.loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Wachtwoord verificatie</label>
                            <input
                                type="password" className="form-control" id="passwordConfirm" name="passwordConfirm"
                                value={passwordConfirm} onChange={(event) => setPasswordConfirm(event.target.value)}
                                required
                                autoComplete="new-password"
                                disabled={ authenticatedUser.loading }
                            />
                        </div>
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="terms"
                                name="terms"
                                required
                                checked={acceptTerms}
                                onChange={(event) => setAcceptTerms(event.target.checked)}
                                disabled={ authenticatedUser.loading }
                            />
                            <label className="form-check-label" htmlFor="terms">
                                Ik ga akkoord met de{" "}
                                <a
                                    href="/privacyverklaring"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="text-secondary text-decoration-underline"
                                >
                                    privacyverklaring
                                </a>.
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary float-right"
                            disabled={ authenticatedUser.loading }
                        >
                            Registreren
                        </button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Register);
