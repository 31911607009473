import React from "react";
import axios from "axios";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import * as ReactRouterPropTypes from "react-router-prop-types";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";
import Loading from "../components/Loading";
import Login from "../components/Login";
import Register from "../components/Register";
import Helmet from "../components/Helmet";
import EventBanner from "../components/event/EventBanner";
import PriceFormatter from "../components/formatters/priceFormatter";

class AcceptInvite extends React.Component {
    state = {
        token: null,
        event: null,
        validToken: null,
        paymentRequired: null,
        eventTicketType: null,
        tokenError: null,
        tokenErrorCode: null,
        register: false,

        redeemUpdating: false,
        redeemError: null,
        redeemSuccess: false
    };

    componentDidMount() {
        const token = this.props.match.params.token;
        console.log("Token: " + token);
        this.setState({ token: token });
        this.validateEventInvite(token);
    }

    validateEventInvite(token) {
        this.setState({ validToken: null, tokenError: null, tokenErrorCode: null });
        axios.post("/validateEventInvite", { token: token })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        validToken: true,
                        paymentRequired: response.data.paymentRequired,
                        eventTicketType: response.data.eventTicketType,
                        event: response.data.event
                    });
                } else {
                    if(response.data.event) {
                        this.setState({ event: response.data.event });
                    }
                    this.setState({ tokenErrorCode: response.data.error });
                    if(response.data.error === "DOESNT_EXIST") {
                        this.setState({ tokenError: "Uitnodiging bestaat niet!" });
                    } else if(response.data.error === "INVITE_USED") {
                        this.setState({ tokenError: "Uitnodiging is al gebruikt!" });
                    } else if(response.data.error === "INVITE_EXPIRED") {
                        this.setState({ tokenError: "Uitnodiging is verlopen! Meld je opnieuw aan bij het evenement om te kunnen deelnemen." });
                    } else {
                        this.setState({ tokenError: "Er ging iets fout. Probeer het later opnieuw." });
                    }
                }
            })
            .catch(() => {
                this.setState({ tokenError: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    redeemEventInvite(token) {
        this.setState({ redeemUpdating: true, redeemError: null });
        axios.post("/redeemEventInvite", { token: token })
            .then((response) => {
                this.setState({ redeemUpdating: false });
                if(response.data.valid) {
                    this.setState({ redeemSuccess: true });
                } else {
                    if(response.data.error === "DOESNT_EXIST") {
                        this.setState({ redeemError: "Uitnodiging bestaat niet!" });
                    } else if(response.data.error === "INVITE_USED") {
                        this.setState({ redeemError: "Uitnodiging is al gebruikt!" });
                    } else if(response.data.error === "USER_ALREADY_REGISTERED") {
                        this.setState({ redeemError: "Je bent al geregistreerd voor dit evenement!" });
                    } else {
                        this.setState({ redeemError: "Er ging iets fout. Probeer het later opnieuw." });
                    }
                }
            })
            .catch(() => {
                this.setState({ redeemError: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    initiateEventInvitePayment(token) {
        this.setState({ redeemUpdating: true, redeemError: null });
        axios.post("/initiateEventInvitePayment", { token: token })
            .then((response) => {
                this.setState({ redeemUpdating: false });
                if(response.data.valid) {
                    console.log(response.data.checkoutUrl);
                    window.location.href = response.data.checkoutUrl;
                } else {
                    if(response.data.error === "DOESNT_EXIST") {
                        this.setState({ redeemError: "Uitnodiging bestaat niet!" });
                    } else if(response.data.error === "INVITE_USED") {
                        this.setState({ redeemError: "Uitnodiging is al gebruikt!" });
                    } else if(response.data.error === "USER_ALREADY_REGISTERED") {
                        this.setState({ redeemError: "Je bent al geregistreerd voor dit evenement!" });
                    } else {
                        this.setState({ redeemError: "Er ging iets fout. Probeer het later opnieuw." });
                    }
                }
            })
            .catch(() => {
                this.setState({ redeemError: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    handleLogout(authenticatedUserContext) {
        authenticatedUserContext.logoutFunction();
        this.setState({ redeemError: null });
    }

    render() {
        const {
            event,
            tokenError,
            tokenErrorCode,
            validToken,
            paymentRequired,
            eventTicketType,
            register,
            redeemUpdating,
            redeemError,
            redeemSuccess
        } = this.state;
        const {
            user
        } = this.context;
        return (
            <React.Fragment>
                <Helmet title="Uitnodiging"/>
                <div className="background-black">
                    <EventBanner event={ event }/>
                    <div className="container pt-4">
                        <div className="row">
                            <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 offset-sm-1 col-sm-10">
                                <h1 className="text-white text-center">
                                    Uitnodiging
                                </h1>
                                { tokenError || validToken === false ? (
                                    <React.Fragment>
                                        <Alert variant="danger">{ tokenError ?? "Er ging iets fout. Probeer het later opnieuw." }</Alert>
                                        { event && tokenErrorCode === "INVITE_EXPIRED" && (
                                            <div className="text-center">
                                                <a
                                                    href={event.infoWebpage}
                                                    className="btn btn-primary"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Naar evenement
                                                </a>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ) : validToken === null ? (
                                    <Loading white/>
                                ) : user === null ? (
                                    <div className="text-center">
                                        <p className="text-white">
                                            Log hieronder in om de uitnodiging te accepteren en je te registreren voor het evenement.
                                        </p>
                                        { register ? (
                                            <React.Fragment>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="mb-3"
                                                    onClick={ () => this.setState({ register: false }) }
                                                >
                                                    Ik heb al een account
                                                </Button>
                                                <Register/>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="mb-3"
                                                    onClick={ () => this.setState({ register: true }) }
                                                >
                                                    Ik heb nog geen account
                                                </Button>
                                                <Login/>
                                            </React.Fragment>
                                        )}
                                    </div>
                                ) : paymentRequired ? (
                                    <div className="card mt-2">
                                        <div className="card-body">
                                            { redeemError && (
                                                <Alert variant="danger">{ redeemError }</Alert>
                                            )}
                                            <p className="card-text">
                                                Je bent ingelogd als <b>{user.name}</b> ({user.email}).
                                            </p>
                                            <p className="card-text">
                                                Weet je zeker dat je dit account wilt
                                                registeren bij dit evenement? De uitnodiging kan maar eenmalig
                                                gebruikt worden.
                                            </p>
                                            <p className="card-text">
                                                Voor deze uitnodiging is een betaling vereist. Klik op de onderstaande
                                                knop om de betaling te voltooien.
                                            </p>
                                            { eventTicketType && (
                                                <p className="card-text">
                                                    <b>
                                                        { eventTicketType.name } - <PriceFormatter price={ eventTicketType.paymentPrice }/>
                                                    </b>
                                                </p>
                                            )}
                                            <Button
                                                variant="primary"
                                                className="float-right"
                                                onClick={ () => this.initiateEventInvitePayment(this.state.token) }
                                                disabled={ redeemUpdating }
                                            >
                                                Betalen
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className="mr-2 float-right"
                                                onClick={ () => this.handleLogout(this.context) }
                                                disabled={ redeemUpdating }
                                            >
                                                Ander account
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card mt-2">
                                        <div className="card-body">
                                            { redeemUpdating ? (
                                                <Loading/>
                                            ) : (
                                                <React.Fragment>
                                                    { redeemError && (
                                                        <Alert variant="danger">{ redeemError }</Alert>
                                                    )}
                                                    { redeemSuccess ? (
                                                        <React.Fragment>
                                                            <Alert variant="success" className="mb-0">
                                                                Je bent nu geregistreerd voor dit evenement! Open de app op je mobiele telefoon voor meer details.
                                                            </Alert>
                                                            <Link to={ `/event/${ event.id }` } className="btn btn-primary mt-3 text-center">
                                                                Naar evenement
                                                            </Link>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <p className="card-text">
                                                                Je bent ingelogd als <b>{ user.name }</b> ({ user.email }).
                                                            </p>
                                                            <p className="card-text">
                                                                Weet je zeker dat je dit account wilt
                                                                registeren bij dit evenement? De uitnodiging kan maar eenmalig
                                                                gebruikt worden.
                                                            </p>
                                                            <Button
                                                                variant="primary"
                                                                className="float-right"
                                                                onClick={ () => this.redeemEventInvite(this.state.token) }
                                                                disabled={ redeemUpdating }
                                                            >
                                                                Registeren
                                                            </Button>
                                                            <Button
                                                                variant="secondary"
                                                                className="mr-2 float-right"
                                                                onClick={ () => this.handleLogout(this.context) }
                                                                disabled={ redeemUpdating }
                                                            >
                                                                Ander account
                                                            </Button>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
AcceptInvite.contextType = AuthenticatedUserContext;
AcceptInvite.propTypes = {
    match: ReactRouterPropTypes.match.isRequired
};

export default withRouter(AcceptInvite);
