import {
    useEffect
} from "react";
import {
    useHistory
} from "react-router-dom";
import ReactGA from "react-ga4";

function useTracking() {
    const {
        listen
    } = useHistory();

    useEffect(() => {
        return listen((location) => {
            // Update Google Tag Manager / Google Analytics
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        });
    }, [listen]);
}

export default useTracking;
