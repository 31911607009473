import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../Loading";
import EventCard from "./EventCard";

function DiscoverList() {
    const [currentEvents, setCurrentEvents] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState(null);
    const [highlightedEvents, setHighlightedEvents] = useState(null);
    const [error, setError] = useState(null);

    const refreshEvents = useCallback(async () => {
        setError(null);
        setCurrentEvents(null);
        setUpcomingEvents(null);
        setHighlightedEvents(null);
        try {
            const response = await axios.get("/getHighlightedEvents");

            const filteredCurrentEvents = response.data.participating.filter((event) => {
                return event.live;
            });
            const filteredUpcomingEvents = response.data.participating.filter((event) => {
                return !event.live;
            });

            setCurrentEvents(filteredCurrentEvents);
            setUpcomingEvents(filteredUpcomingEvents);

            setHighlightedEvents(response.data.highlightedEvents.filter((event) => {
                return response.data.participating.findIndex((participatingEvent) => participatingEvent.id === event.id) === -1;
            }));
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van de evenementen. Probeer het later opnieuw.");
        }
    }, []);
    useEffect(() => {
        refreshEvents();
    }, [refreshEvents]);

    return (
        <React.Fragment>
            <h1 className="text-white text-center mt-3 mb-2">
                Ontdek
            </h1>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (!currentEvents || !upcomingEvents || !highlightedEvents) ? (
                <Loading white/>
            ) : (
                <div className="container">
                    { currentEvents.length > 0 && (
                        <h5 className="text-white mt-3">
                            Huidige evenementen
                        </h5>
                    )}
                    <div className="row">
                        { currentEvents.map((event) => (
                            <div key={event.id} className="col-sm-6 col-xl-4">
                                <EventCard event={event}/>
                            </div>
                        ))}
                    </div>

                    { upcomingEvents.length > 0 && (
                        <h5 className="text-white mt-3">
                            Opkomende evenementen
                        </h5>
                    )}
                    <div className="row">
                        { upcomingEvents.map((event) => (
                            <div key={event.id} className="col-sm-6 col-xl-4">
                                <EventCard event={event}/>
                            </div>
                        ))}
                    </div>

                    { highlightedEvents.length > 0 && (
                        <h5 className="text-white mt-3">
                            Uitgelichte evenementen
                        </h5>
                    )}
                    <div className="row">
                        { highlightedEvents.map((event) => (
                            <div key={event.id} className="col-sm-6 col-xl-4">
                                <EventCard event={event}/>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(DiscoverList);
