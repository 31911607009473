import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import PropTypes from "prop-types";

function EventDetailLink({ icon, children, href, to }) {
    const buttonContent = useMemo(() => (
        <div
            className="event-detail-button"
        >
            <div className="event-detail-button-icon">
                <i className={ icon }/>
            </div>
            <span className="event-detail-button-text">
                { children }
            </span>
        </div>
    ), [icon, children]);
    if (to) {
        return (
            <Link
                to={ to }
                style={{ textDecoration: "none" }}
            >
                { buttonContent }
            </Link>
        );
    }
    return (
        <a
            href={ href }
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none" }}
        >
            { buttonContent }
        </a>
    );
}

EventDetailLink.propTypes = {
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    to: PropTypes.string
};

export default React.memo(EventDetailLink);
