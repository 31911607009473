import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const authenticatedUser = useContext(AuthenticatedUserContext);

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
        };
    }, []);
    const state = useMemo(() => {
        return {
            email,
            password
        };
    }, [email, password]);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            authenticatedUser.loginFunction(state, onError);
        };
    }, [state, onError, authenticatedUser]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card">
                <div className="card-body">
                    <form id="login-form" onSubmit={ onSubmit }>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email" className="form-control" id="email" name="email"
                                value={email} onChange={(event) => setEmail(event.target.value)}
                                required
                                autoComplete="email"
                                disabled={ authenticatedUser.loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Wachtwoord</label>
                            <input
                                type="password" className="form-control" id="password" name="password"
                                value={password} onChange={(event) => setPassword(event.target.value)}
                                required
                                autoComplete="current-password"
                                disabled={ authenticatedUser.loading }
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <Link
                                    to="/forgot-password"
                                    className="btn btn-link btn-sm px-0"
                                    style={{ color: "rgb(31, 35, 38)" }}
                                >
                                    Wachtwoord vergeten?
                                </Link>
                            </div>
                            <div className="ml-2">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={ authenticatedUser.loading }
                                >
                                    Inloggen
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Login);
