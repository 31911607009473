import React, {
    useCallback,
    useEffect,
    useRef
} from "react";
import PropTypes from "prop-types";

// https://dev.to/mcavaliere/comparing-previous-useeffect-values-in-react-2o4g
function usePreviousValue(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

function BackgroundImageLoad({ src, setLoaded }) {
    const previousSrc = usePreviousValue(src);
    useEffect(() => {
        if(src === previousSrc) {
            return;
        }
        setLoaded(false);
    }, [src, setLoaded]);
    const onLoaded = useCallback(() => {
        setLoaded(true);
    }, [setLoaded]);

    return (
        <img
            src={ src }
            alt="tmp"
            className="d-none"
            onLoad={ onLoaded }
        />
    );
}
BackgroundImageLoad.propTypes = {
    src: PropTypes.string,
    setLoaded: PropTypes.func.isRequired
};

export default React.memo(BackgroundImageLoad);
