import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../components/Helmet";
import Login from "../components/Login";
import Logo from "../components/Logo";
import AppStoreLink from "../components/AppStoreLink";

function LoginPage() {
    return (
        <React.Fragment>
            <Helmet
                title="Home"
            />
            <div className="background-login">
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <div className="d-flex flex-column align-items-center mt-3 w-100">
                        <h1 className="text-white text-center mb-3">Login</h1>
                        <Link to="/register" className="btn btn-primary btn-sm mb-3">
                            Ik heb nog geen account
                        </Link>
                        <div style={{ width: "100%", maxWidth: "500px" }}>
                            <Login/>
                        </div>
                    </div>
                    <AppStoreLink/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(LoginPage);
