import React from "react";
import PropTypes from "prop-types";

function EventDetailButton({ icon, children, onClick }) {
    return (
        <div
            className="event-detail-button"
            onClick={ onClick }
            style={{
                cursor: onClick ? "pointer" : "default",
            }}
        >
            <div className="event-detail-button-icon">
                <i className={ icon }/>
            </div>
            <span className="event-detail-button-text">
                { children }
            </span>
        </div>
    );
}
EventDetailButton.propTypes = {
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

export default React.memo(EventDetailButton);
