import React, {
    useCallback, useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link,
    useParams
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";

import Helmet from "../components/Helmet";
import Loading from "../components/Loading";
import EventBanner from "../components/event/EventBanner";

function InvitePaymentFinalize() {
    const {
        paymentToken
    } = useParams();
    const [event, setEvent] = useState(null);
    const [status, setStatus] = useState(null);
    const [inviteToken, setInviteToken] = useState(null);
    const [error, setError] = useState(null);

    const checkStatus = useCallback(async () => {
        setError(null);
        setStatus(null);
        try {
            const response = await axios.post("redeemEventInvitePayment", {
                paymentToken: paymentToken
            });
            console.log(response.data);
            setEvent(response.data.event);
            setStatus(response.data.status);
            setInviteToken(response.data.inviteToken);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van de betalingsstatus. Probeer het opnieuw.");
        }
    }, [paymentToken]);
    useEffect(() => {
        checkStatus();
    }, [checkStatus]);

    useEffect(() => {
        if(status !== "pending") {
            return;
        }
        const interval = setInterval(() => {
            checkStatus();
        }, 5000);
        return () => clearInterval(interval);
    }, [checkStatus, status]);

    return (
        <React.Fragment>
            <Helmet
                title={"Betaling ticket " + (event?.name ?? "evenement")}
            />
            <div className="background-black">
                <EventBanner event={ event }/>
                <div className="d-flex flex-column align-items-center w-100">
                    { error ? (
                        <React.Fragment>
                            <Alert variant="danger">{ error }</Alert>
                            <Button
                                variant="primary"
                                onClick={ checkStatus }
                            >
                                Refresh
                            </Button>
                        </React.Fragment>
                    ) : !status ? (
                        <Loading white/>
                    ) : status === "pending" ? (
                        <Loading white/>
                    ) : status === "canceled" ? (
                        <div className="card mt-2">
                            <div className="card-body">
                                <h5 className="card-title">Betaling geannuleerd</h5>
                                <p className="card-text">
                                    De betaling voor het ticket van { event?.name ?? "het evenement" } is geannuleerd.
                                </p>
                                { inviteToken && (
                                    <Link to={`/accept-invite/${inviteToken}`} className="btn btn-primary">
                                        Terug naar uitnodiging
                                    </Link>
                                )}
                            </div>
                        </div>
                    ) : status === "paid" ? (
                        <div className="card mt-2">
                            <div className="card-body">
                                <h5 className="card-title">Betaling afgerond!</h5>
                                <p className="card-text">
                                    De betaling voor het ticket van { event?.name ?? "het evenement" } is afgerond.
                                    Het ticket is nu beschikbaar in je account.
                                </p>
                                { event ? (
                                    <Link to={`/event/${event?.id}`} className="btn btn-primary">
                                        Naar evenement
                                    </Link>
                                ) : (
                                    <Link to="/discover" className="btn btn-primary">
                                        Naar app
                                    </Link>
                                )}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <Alert variant="danger">Er ging iets fout bij het ophalen van de betalingsstatus. Probeer het opnieuw.</Alert>
                            <Button
                                variant="primary"
                                onClick={ checkStatus }
                            >
                                Refresh
                            </Button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(InvitePaymentFinalize);
