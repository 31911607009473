import {
    useEffect
} from "react";
import {
    withRouter
} from "react-router-dom";
import PropTypes from "prop-types";

function ScrollToTop({ history, exclude = [] }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            if(!exclude.includes(history.location.pathname)) {
                window.scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        };
    }, [history, exclude]);

    return null;
}
ScrollToTop.propTypes = {
    history: PropTypes.object.isRequired,
    exclude: PropTypes.array
};

export default withRouter(ScrollToTop);
