import React from "react";

import logo from "../img/logo-background.svg";

function Logo() {
    return (
        <img
            src={ logo }
            className="img-fluid d-inline-block align-top"
            alt="DRVR"
            style={{ maxHeight: "100px" }}
        />
    );
}

export default React.memo(Logo);
