import React from "react";
import {
    Link
} from "react-router-dom";
import PropTypes from "prop-types";

import defaultBanner from "../../img/default-event-banner.jpg";

function EventCard({ event }) {
    return (
        <Link
            to={`/event/${event.id}`}
            className="card-event-link"
        >
            <div
                className={ "card-event w-100" + (event.live ? " live" : "") }
                style={{
                    backgroundImage: `url(${event.banner?.url ?? defaultBanner})`,
                }}
            >
                { event.live && (
                    <div className="live-badge">
                        LIVE
                    </div>
                )}
                { event.logo ? (
                    <div
                        className="event-logo"
                        style={{
                            backgroundImage: `url(${event.logo.url})`,
                        }}
                    />
                ) : (
                    <div className="flex-grow-1"/>
                )}
                <div className="event-title-container">
                    <h6 className="event-title">
                        { event.name }
                    </h6>
                </div>
            </div>
        </Link>
    );
}

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
};

export default React.memo(EventCard);
