import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";
import PropTypes from "prop-types";

function Helmet({ title = null }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? "DRVR app" : (title + " - DRVR app") }
            </title>
        </ReactHelmet>
    );
}
Helmet.propTypes = {
    title: PropTypes.string
};

export default React.memo(Helmet);
