import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    useHistory,
    useParams
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Helmet from "../components/Helmet";
import Loading from "../components/Loading";
import EventTicketModal from "../components/event/EventTicketModal";
import EventDetailLink from "../components/EventDetailLink";
import EventBanner from "../components/event/EventBanner";
import EventDetailButton from "../components/EventDetailButton";
import EventInfoModal from "../components/event/EventInfoModal";

function EventPage() {
    const {
        eventId,
        page
    } = useParams();
    const [event, setEvent] = useState(null);
    const [participating, setParticipating] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    const refreshEvent = useCallback(async () => {
        setEvent(null);
        setError(null);
        try {
            const response = await axios.post("/getEvent", { eventId: eventId });
            setEvent(response.data.event);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van het evenement. Probeer het later opnieuw.");
        }
    }, [eventId]);
    const refreshParticipating = useCallback(async () => {
        if(!event) {
            return;
        }
        setParticipating(null);
        setError(null);
        try {
            const response = await axios.post("/getEventParticipation", { eventId: event.id });
            setParticipating(response.data.participating);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van de deelname status. Probeer het later opnieuw.");
        }
    }, [event]);
    useEffect(() => {
        refreshEvent();
    }, [refreshEvent]);
    useEffect(() => {
        refreshParticipating();
    }, [refreshParticipating]);

    return (
        <React.Fragment>
            <Helmet
                title={ event?.name ?? "Evenement" }
            />
            <div className="background-black">
                <div className="d-flex flex-column align-items-center w-100">
                    <EventBanner
                        event={ event }
                        backLink="/discover"
                    />
                    { error ? (
                        <Alert variant="danger">{ error }</Alert>
                    ) : !event ? (
                        <Loading white/>
                    ) : (
                        <React.Fragment>
                            <EventTicketModal
                                event={ event }
                                show={ page?.toLowerCase() === "ticket" }
                                handleClose={ () => history.replace(`/event/${eventId}`) }
                            />
                            <EventInfoModal
                                event={ event }
                                show={ page?.toLowerCase() === "info" }
                                handleClose={ () => history.replace(`/event/${eventId}`) }
                            />
                            { participating === null ? (
                                <Loading white/>
                            ) : participating ? (
                                <div className="d-flex flex-row justify-content-center flex-wrap">
                                    <EventDetailButton
                                        icon="fa-solid fa-ticket"
                                        onClick={ () => history.replace(`/event/${eventId}/ticket`) }
                                    >
                                        Ticket
                                    </EventDetailButton>
                                    <EventDetailButton
                                        icon="fa-solid fa-square-info"
                                        onClick={ () => history.replace(`/event/${eventId}/info`) }
                                    >
                                        Info
                                    </EventDetailButton>
                                </div>
                            ) : (
                                <EventDetailLink
                                    icon="fa-solid fa-ticket"
                                    href={ event.infoWebpage }
                                >
                                    Aanmelden
                                </EventDetailLink>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(EventPage);
