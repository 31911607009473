import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import {
    NewTabOpener
} from "new-tab-opener";

import Loading from "../Loading";
import downloadFile from "../../utilities/downloadFile";

function EventTicketModal({ event, show, handleClose }) {
    const [ticketToken, setTicketToken] = useState(null);
    const [participant, setParticipant] = useState(null);
    const [downloadingPDF, setDownloadingPDF] = useState(false);

    const [error, setError] = useState(null);
    const [pdfError, setPDFError] = useState(null);
    const [success, setSuccess] = useState(null);

    const refreshTicket = useCallback(async () => {
        setError(null);
        try {
            const response = await axios.post("/getEventTicket", { eventId: event.id });
            setTicketToken(response.data.ticket);
            setParticipant(response.data.participant);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van het ticket. Probeer het later opnieuw.");
        }
    }, [event]);
    useEffect(() => {
        if(!show) {
            return;
        }
        setTicketToken(null);
        setParticipant(null);
        setError(null);
        setPDFError(null);
        setSuccess(null);

        refreshTicket();

        const interval = setInterval(() => {
            refreshTicket();
        }, 15 * 1000);
        return () => clearInterval(interval);
    }, [refreshTicket, show]);

    const downloadPDF = useCallback(async () => {
        setDownloadingPDF(true);
        setPDFError(null);
        setSuccess(null);
        try {
            const tabOpener = new NewTabOpener();
            const response = await axios.get(`/getEventTicketPDF?eventId=${event.id}`, { responseType: "blob" });
            const fileName = `Ticket - ${participant.name} - ${event.name}.pdf`;
            if(tabOpener.shouldPreOpenWindow()) {
                const blob = new Blob([response.data], {type: "application/pdf"});
                const url = URL.createObjectURL(blob);
                tabOpener.open(url);
            } else {
                setSuccess("PDF staat bij je downloads.");
                downloadFile(response.data, fileName, "application/pdf");
            }
        } catch(requestError) {
            Sentry.captureException(requestError);
            console.error(requestError);
            setPDFError("Er ging iets fout bij het downloaden van de PDF. Probeer het later opnieuw.");
        } finally {
            setDownloadingPDF(false);
        }
    }, [event, participant]);

    return (
        <Modal
            show={ show }
            onHide={ handleClose }
            className="modal-dark"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Ticket
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !ticketToken ? (
                    <Loading white/>
                ) : (
                    <React.Fragment>
                        { pdfError && (
                            <Alert variant="danger">{ pdfError }</Alert>
                        )}
                        { success && (
                            <Alert variant="success">{ success }</Alert>
                        )}
                        { participant.ticketScanned && (
                            <p className="ticket-scanned text-center">
                                Je ticket is gescand!
                            </p>
                        )}
                        <div className="text-center">
                            <img
                                src={ axios.defaults.baseURL + "generateQRCode?content=" + ticketToken }
                                alt="Ticket QR Code"
                                style={{ filter: "invert(1)" }}
                                className="img-fluid"
                            />
                            { participant.eventTicketType && (
                                <React.Fragment>
                                    <h5 className="mt-0">
                                        { participant.eventTicketType.name }
                                    </h5>
                                    { participant.eventTicketType.description?.trim().length > 0 && (
                                        <p>
                                            { participant.eventTicketType.description }
                                        </p>
                                    )}
                                </React.Fragment>
                            )}
                            <p className="mb-0">
                                Laat de QR Code zien aan mensen van de organisatie om je aan te melden.
                            </p>
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ downloadingPDF }
                >
                    Sluiten
                </Button>
                <Button
                    variant="primary"
                    onClick={ downloadPDF }
                    disabled={ downloadingPDF }
                >
                    { downloadingPDF && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    <i className="fa-solid fa-file-arrow-down mr-2"/>
                    Download PDF
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
EventTicketModal.propTypes = {
    event: PropTypes.object,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default React.memo(EventTicketModal);
