import React from "react";
import { createRoot } from "react-dom/client";

import axios from "axios";
import {
    stringify
} from "qs";
import * as Sentry from "@sentry/react";
import {
    Integrations
} from "@sentry/tracing";

import ReactGA from "react-ga4";
import {
    clarity
} from "clarity-js";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://609a13e5882e4beea07ad6f218f62810@sentry1.zsnode.com/40",
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

// Setup Google Analytics
if(development) {
    console.log("Running in development mode. Google Analytics is disabled.");
} else {
    ReactGA.initialize("G-E922Y70RD6");
}

// Setup Microsoft Clarity
if(development) {
    console.log("Running in development mode. Microsoft Clarity is disabled.");
} else {
    clarity.start({
        projectId: "lmcsxcaeso",
        upload: "https://m.clarity.ms/collect",
        track: true,
        content: true
    });
}

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

if(development) {
    axios.defaults.baseURL = "/v1/";
} else {
    const DRVR_API_URL = process.env.REACT_APP_DRVR_API_URL ? process.env.REACT_APP_DRVR_API_URL : "api.drvr-app.com";
    axios.defaults.baseURL = "https://" + DRVR_API_URL + "/v1/";
}
axios.defaults.withCredentials = true;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
