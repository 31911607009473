import React from "react";
import axios from "axios";
import {
    BrowserRouter as Router
} from "react-router-dom";
import {
    CSSTransition
} from "react-transition-group";

import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import Logo from "./components/Logo";
import Loading from "./components/Loading";
import ScrollToTop from "./hooks/ScrollToTop";
import DRVRRouter from "./DRVRRouter";

import "./scss/main.scss";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            loading: false,
            loginFunction: this.login.bind(this),
            registerFunction: this.register.bind(this),
            logoutFunction: this.logout.bind(this)
        };
    }

    componentDidMount() {
        this.getSession();
    }

    login(state, onErrorOccurred) {
        let email = state.email;
        let password = state.password;

        this.setState({ loading: true });
        axios.post("/login", { email: email, password: password, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: response.data.user });
                } else {
                    if(response.data.error === "INVALID_CREDENTIALS") {
                        onErrorOccurred("Foutieve gebruikersnaam of wachtwoord.");
                    } else {
                        onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                    }
                }
            })
            .catch(() => {
                onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    register(state, onErrorOccurred) {
        console.log(state);
        const {
            fullName,
            email,
            password,
            passwordConfirm
        } = state;

        if(fullName.length <= 0 || email.length <= 0 || password.length <= 0) {
            onErrorOccurred("Niet alle velden zijn ingevuld.");
            return;
        }
        if(password.length < 8) {
            onErrorOccurred("Wachtwoord is te kort.");
            return;
        }
        if(password !== passwordConfirm) {
            onErrorOccurred("Wachtwoorden zijn niet hetzelfde.");
            return;
        }

        this.setState({ loading: true });
        axios.post("/register", { fullName: fullName, email: email, password: password, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: response.data.user });
                } else {
                    if(response.data.error === "INVALID_EMAIL") {
                        onErrorOccurred("Het opgegeven email adres is niet geldig.");
                    } else if(response.data.error === "EMAIL_IN_USE") {
                        onErrorOccurred("Het opgegeven email adres is al in gebruik.");
                    } else {
                        onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                    }
                }
            })
            .catch(() => {
                onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getSession() {
        this.setState({ loading: true });
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: response.data.user });
                } else {
                    this.setState({ user: null });
                }
            })
            .catch(() => {
                this.setState({ user: null });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    logout() {
        axios.get("/logout")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: null });
                }
            })
            .catch(() => {
                // TODO: Display pop-over error message.
            });
    }

    render() {
        return (
            <AuthenticatedUserContext.Provider value={ this.state }>
                <CSSTransition classNames="fade" unmountOnExit in={ this.state.user === undefined } timeout={ 500 }>
                    <div className="session-load-overlay">
                        <div
                            className="flex-grow-1 d-flex flex-column align-items-center w-100"
                        >
                            <div className="mb-3 w-100 d-flex justify-content-center">
                                <Logo/>
                            </div>
                            <Loading white/>
                        </div>
                    </div>
                </CSSTransition>

                { this.state.user !== undefined && (
                    <Router>
                        <ScrollToTop/>
                        <DRVRRouter/>
                    </Router>
                )}
            </AuthenticatedUserContext.Provider>
        );
    }
}

export default App;
