import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import Loading from "../components/Loading";
import Helmet from "../components/Helmet";

function PrivacyPolicy({ clean = false }) {
    const [markdown, setMarkdown] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.create({ baseURL: "/" }).get("/privacypolicy.md")
            .then((response) => {
                setMarkdown(response.data);
            })
            .catch(() => {
                setError("Er ging iets fout bij het ophalen van de privacyverklaring. Probeer het later opnieuw.");
            });
    }, []);

    return (
        <React.Fragment>
            <Helmet
                title="Privacyverklaring"
            />
            <div className="container">
                { !clean && (
                    <h1 className="text-center">Privacyverklaring</h1>
                )}
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !markdown ? (
                    <Loading/>
                ) : (
                    <div className="my-2">
                        <ReactMarkdown>
                            { markdown }
                        </ReactMarkdown>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
PrivacyPolicy.propTypes = {
    clean: PropTypes.bool
};

export default React.memo(PrivacyPolicy);
