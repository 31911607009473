import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";

import DateFormatter from "../DateFormatter";

function InfoDetailSection({ children, className }) {
    return (
        <div className={ "info-detail-section" + (className ? ` ${className}` : "") }>
            { children }
        </div>
    );
}
InfoDetailSection.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

function InfoDetailRow({ title, children }) {
    return (
        <div className="info-detail-row">
            <div className="info-detail-row-title">
                { title }
            </div>
            <div className="info-detail-row-content">
                { children }
            </div>
        </div>
    );
}
InfoDetailRow.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
};

function InfoDetailLink({ href, title, children }) {
    return (
        <a
            href={ href }
            target="_blank"
            rel="noreferrer noopener"
            className="info-detail-link"
            style={{ textDecoration: "none" }}
        >
            <div className="info-detail-row d-flex flex-row align-items-center">
                <div className="flex-grow-1">
                    { title && (
                        <div className="info-detail-row-title">
                            { title }
                        </div>
                    )}
                    <div className={ "info-detail-row-content" + (title ? " text-white" : "") }>
                        { children }
                    </div>
                </div>
                <div className="info-detail-link-icon">
                    <i className="fa-solid fa-angle-right"/>
                </div>
            </div>
        </a>
    );
}

InfoDetailLink.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node
};

function EventInfoModal({event, show, handleClose}) {
    return (
        <Modal
            show={show}
            onHide={ handleClose }
            className="modal-dark"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    { event.name }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                { (event.startDate || event.endDate) && (
                    <InfoDetailSection>
                        { event.startDate && (
                            <InfoDetailRow title="Start datum">
                                <DateFormatter date={ event.startDate } format="DD-MM-YYYY HH:mm"/>
                            </InfoDetailRow>
                        )}
                        { event.endDate && (
                            <InfoDetailRow title="Eind datum">
                                <DateFormatter date={ event.endDate } format="DD-MM-YYYY HH:mm"/>
                            </InfoDetailRow>
                        )}
                    </InfoDetailSection>
                )}

                { event.description?.length > 0 && (
                    <InfoDetailSection>
                        <InfoDetailRow title="Beschrijving">
                            { event.description }
                        </InfoDetailRow>
                    </InfoDetailSection>
                )}

                { event.address && (
                    <InfoDetailSection>
                        { event.latitude && event.longitude ? (
                            <InfoDetailLink
                                title="Adres"
                                href={ `https://maps.google.com/?q=${event.latitude},${event.longitude}` }
                            >
                                { event.address }
                            </InfoDetailLink>
                        ) : (
                            <InfoDetailRow title="Adres">
                                { event.address }
                            </InfoDetailRow>
                        )}
                    </InfoDetailSection>
                )}

                { event.infoWebpage && (
                    <InfoDetailSection>
                        <InfoDetailLink href={ event.infoWebpage }>
                            Webpagina
                        </InfoDetailLink>
                    </InfoDetailSection>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
EventInfoModal.propTypes = {
    event: PropTypes.object,
    show: PropTypes.bool,
    handleClose: PropTypes.func
};

export default React.memo(EventInfoModal);
