import React from "react";
import appStore from "../img/appstore.svg";

function AppStoreLink() {
    return (
        <div className="mt-4" style={{maxWidth: "200px"}}>
            <a
                href="https://apps.apple.com/hr/app/drvr/id1480123933"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={appStore}
                    alt="App Store"
                    className="img-fluid"
                />
            </a>
        </div>
    );
}

export default React.memo(AppStoreLink);
