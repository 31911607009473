import React from "react";
import Spinner from "react-spinner-material";
import PropTypes from "prop-types";

function Loading({ white = false }) {
    return (
        <div className="d-flex w-100 h-100 justify-content-center align-content-center mt-2 mb-2">
            <Spinner
                radius={ 40 }
                color={ white ? "#fff" : "#1f2326" }
                stroke={ 5 }
                visible={ true }
            />
        </div>
    );
}
Loading.propTypes = {
    white: PropTypes.bool
};

export default React.memo(Loading);
