import React from "react";
import PropTypes from "prop-types";
import {
    Route,
    Redirect
} from "react-router-dom";

function PrivateRoute({children, authenticated, target = "/", component, ...rest}) {
    const ComponentObject = component;
    let renderRoute = children;
    if (component) {
        renderRoute = <ComponentObject/>;
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                authenticated ? (
                    renderRoute
                ) : (
                    <Redirect
                        to={{
                            pathname: target,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}
PrivateRoute.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ]),
    authenticated: PropTypes.bool,
    target: PropTypes.string,
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.elementType
    ])
};

export default PrivateRoute;
