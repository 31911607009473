import React from "react";

import logo from "../img/logo.svg";

function Header() {
    return (
        <div className="w-100 bg-primary text-center p-4 mb-3">
            <img src={logo} height="60" className="d-inline-block align-top" alt="DRVR"/>
        </div>
    );
}

export default React.memo(Header);
