import React from "react";

import Helmet from "../components/Helmet";
import Logo from "../components/Logo";
import DiscoverList from "../components/discover/DiscoverList";

function Discover() {
    return (
        <React.Fragment>
            <Helmet
                title="Ontdek"
            />
            <div className="background-login">
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <DiscoverList/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Discover);
