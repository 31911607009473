import React, {
    useCallback,
    useState
} from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../components/Helmet";
import Logo from "../components/Logo";
import {Alert} from "react-bootstrap";
import axios from "axios";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);
        setLoading(true);
        try {
            await axios.post("/initiateForgotPassword", { email });
            setSuccess("Er is een email verstuurd met instructies om je wachtwoord te resetten.");
        } catch(requestError) {
            setError("Er ging iets fout. Probeer het later nog eens.");
        }
        setLoading(false);
    }, [email, error, loading]);

    return (
        <React.Fragment>
            <Helmet
                title="Wachtwoord vergeten"
            />
            <div className="background-login">
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <div className="d-flex flex-column align-items-center mt-3 w-100">
                        <h1 className="text-white text-center mb-3">
                            Wachtwoord vergeten
                        </h1>
                        <Link to="/login" className="btn btn-primary btn-sm mb-3">
                            Terug naar inloggen
                        </Link>
                        { success ? (
                            <Alert variant="success">{ success }</Alert>
                        ) : (
                            <div className="card" style={{width: "100%", maxWidth: "500px"}}>
                                <div className="card-body">
                                    <form id="login-form" onSubmit={onSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                                required
                                                autoComplete="email"
                                                disabled={ loading }
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary float-right"
                                            disabled={ loading }
                                        >
                                            Verstuur
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(ForgotPassword);
