import React from "react";
import axios from "axios";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import * as ReactRouterPropTypes from "react-router-prop-types";

import Loading from "../components/Loading";
import Helmet from "../components/Helmet";
import Logo from "../components/Logo";

class ResetPassword extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirm: "",

            validToken: false,
            checkError: null,

            loading: false,
            submitError: null,
            success: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.checkResetPasswordToken();
    }

    checkResetPasswordToken() {
        const { match } = this.props;
        const { token } = match.params;
        this.setState({ validToken: false, checkError: null });
        axios.post("/checkResetPasswordToken", { token })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ validToken: true });
                } else {
                    this.setState({ validToken: false, checkError: "Link is niet (meer) geldig. Vraag een nieuwe link aan." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ validToken: false, checkError: "Er ging iets fout. Probeer het later nog eens." });
            });
    }

    onSubmit(event) {
        event.preventDefault();
        const { match } = this.props;
        const { token } = match.params;
        const {
            password,
            passwordConfirm
        } = this.state;

        if(password !== passwordConfirm) {
            this.setState({ submitError: "De wachtwoorden komen niet overeen." });
            return;
        }
        if(password.length < 8) {
            this.setState({ submitError: "Je wachtwoord moet minimaal 8 karakters lang zijn." });
            return;
        }

        this.setState({ loading: true, submitError: null });
        axios.post("/setUserPassword", { token, password })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ success: true });
                } else {
                    this.setState({ success: false, submitError: "Er ging iets fout. Probeer het later nog eens. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ success: false, submitError: "Er ging iets fout. Probeer het later nog eens." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const {
            password,
            passwordConfirm,

            validToken,
            checkError,

            loading,
            submitError,
            success
        } = this.state;
        return (
            <div className="background-login">
                <Helmet
                    title="Wachtwoord vergeten"
                />
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <div className="d-flex flex-column align-items-center mt-3 w-100">
                        <h1 className="text-white text-center mb-3">
                            Wachtwoord opnieuw instellen
                        </h1>
                        { checkError ? (
                            <Alert variant="danger">{ checkError }</Alert>
                        ) : !validToken ? (
                            <Loading white/>
                        ) : success ? (
                            <React.Fragment>
                                <Alert variant="success">
                                    Het wachtwoord van je account is nu aangepast. Probeer opnieuw in te loggen met je
                                    nieuwe wachtwoord.
                                </Alert>
                                <Link to="/login" className="btn btn-primary btn-sm mb-3">
                                    Terug naar inloggen
                                </Link>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                { submitError && (
                                    <Alert variant="danger">{ submitError }</Alert>
                                )}
                                <div className="card" style={{width: "100%", maxWidth: "500px"}}>
                                    <div className="card-body">
                                        <form onSubmit={ this.onSubmit }>
                                            <div className="form-group">
                                                <label htmlFor="password">
                                                    Wachtwoord
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    disabled={ loading }
                                                    value={ password }
                                                    onChange={(event) => this.setState({ password: event.target.value }) }
                                                    autoComplete="new-password"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="passwordConfirm">
                                                    Wachtwoord controle
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="passwordConfirm"
                                                    disabled={ loading }
                                                    value={ passwordConfirm }
                                                    onChange={(event) => this.setState({ passwordConfirm: event.target.value }) }
                                                    autoComplete="new-password"
                                                />
                                            </div>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="float-right"
                                                disabled={ loading }
                                            >
                                                Versturen
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    match: ReactRouterPropTypes.match.isRequired
};

export default withRouter(ResetPassword);
